import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Alert } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { paths } from 'src/routes/paths';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import { useAuthContext } from 'src/context/AuthContext';
import { usePropertyContext } from 'src/context/PropertyContext';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function LoginBackgroundView() {
  const passwordShow = useBoolean();

  const { login, loginWithGoogle } = useAuthContext();
  const { openLoginModal, openRegisterModal } = usePropertyContext();

  const [errorMsg, setErrorMsg] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('That is not an email'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login(data.email, data.password);
      openLoginModal.onFalse();
      // await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
    } catch (error) {
      console.error(error);
      setErrorMsg(
        typeof error === 'string' ? error : error.message.replace('Firebase: Error ', '')
      );
    }
  });

  const onClickGetStarted = () => {
    openRegisterModal.onTrue();
    openLoginModal.onFalse();
  };
  const onClickGoogle = () => {
    loginWithGoogle();
    openLoginModal.onFalse();
  };

  const renderHead = (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3" paragraph>
          Login
        </Typography>
      </Stack>

      <Stack direction="row" pb={2} spacing={1}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Don’t have an account?
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={onClickGetStarted}
        >
          Get started
        </Typography>
      </Stack>
      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMsg}
        </Alert>
      )}
    </Box>
  );

  const renderSocials = (
    <Stack direction="row" spacing={2}>
      <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onClickGoogle}>
        <Iconify icon="logos:google-icon" width={24} />
      </Button>

      {/* <Button fullWidth size="large" color="inherit" variant="outlined">
        <Iconify icon="carbon:logo-facebook" width={24} sx={{ color: '#1877F2' }} />
      </Button> */}

      {/* <Button color="inherit" fullWidth variant="outlined" size="large">
        <Iconify icon="carbon:logo-github" width={24} sx={{ color: 'text.primary' }} />
      </Button> */}
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField name="email" label="Email address" fullWidth />

        <RHFTextField
          name="password"
          label="Password"
          type={passwordShow.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify icon={passwordShow.value ? 'carbon:view' : 'carbon:view-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Link
          component={RouterLink}
          href={paths.forgotPassword}
          variant="body2"
          underline="always"
          color="text.secondary"
        >
          Forgot password?
        </Link>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <Box sx={{ minWidth: { xs: '100%', sm: '400px' } }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pt: 2 }}>
        <Button color="inherit" onClick={() => openLoginModal.onFalse()}>
          <Iconify icon="ic:round-close" width={24} />
        </Button>
      </Stack>
      <Box sx={{ p: 3, pt: 0 }}>
        {renderHead}

        {renderForm}

        <Divider sx={{ py: 3 }}>
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            or continue with
          </Typography>
        </Divider>

        {renderSocials}
      </Box>
    </Box>
  );
}
