import Dialog from '@mui/material/Dialog';

import { usePropertyContext } from 'src/context/PropertyContext';
import RegisterBackgroundView from 'src/sections/auth/register-background-view';

export default function RegisterModal() {
  const { openRegisterModal } = usePropertyContext();

  const handleClose = () => {
    openRegisterModal.onFalse();
  };

  return (
    <Dialog maxWidth="lg" open={openRegisterModal.value} onClose={handleClose}>
      <RegisterBackgroundView />
    </Dialog>
  );
}
