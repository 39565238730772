import { useState } from 'react';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import {
  Link,
  Avatar,
  Popover,
  Divider,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material';

import { bgBlur } from 'src/theme/css';
import Logo from 'src/components/logo';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { varHover } from 'src/components/animate';
import { RouterLink } from 'src/routes/components';
import { useAuthContext } from 'src/context/AuthContext';
import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { usePropertyContext } from 'src/context/PropertyContext';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { navConfig } from './config-navigation';

// ----------------------------------------------------------------------
const OPTIONS = [
  // {
  //   label: 'Home',
  //   linkTo: '/',
  // },
  // {
  //   label: 'Profile',
  //   linkTo: paths.dashboard.user.profile,
  // },
  {
    label: 'Account',
    linkTo: paths.account.personal,
  },
];
export default function Header({ headerOnDark }) {
  const theme = useTheme();
  const router = useRouter();
  const { user, logout } = useAuthContext();
  const { openLoginModal } = usePropertyContext();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      handleClose();
      // popover.onClose();
      // setReservations([]);
      router.replace('/');
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <Logo sx={{ maxWidth: mdUp ? 130 : 100 }} />
          </Box>

          {mdUp && <NavDesktop data={navConfig} />}

          <Stack spacing={2} direction="row" alignItems="center">
            {mdUp && (
              <Button
                variant="contained"
                color="inherit"
                href={paths.adminPortal}
                target="_blank"
                rel="noopener"
              >
                Owner Portal
              </Button>
            )}
            <Stack
              spacing={3}
              direction="row"
              alignItems="center"
              flexGrow={1}
              justifyContent="flex-end"
            >
              {/* {!mdUp && (
                <IconButton size="small" color="inherit" sx={{ p: 0 }}>
                  <Iconify icon="carbon:search" width={24} />
                </IconButton>
              )} */}

              {/* <Badge badgeContent={2} color="info">
                <IconButton
                  component={RouterLink}
                  href={paths.wishlist}
                  size="small"
                  color="inherit"
                  sx={{ p: 0 }}
                >
                  <Iconify icon="carbon:favorite" width={24} />
                </IconButton>
              </Badge> */}

              {/* <IconButton
                component={RouterLink}
                href={paths.account.personal}
                size="small"
                color="inherit"
                sx={{ p: 0 }}
              >
                <Iconify icon="carbon:user" width={24} />
              </IconButton> */}

              {user?.email ? (
                <IconButton
                  component={m.button}
                  whileTap="tap"
                  whileHover="hover"
                  variants={varHover(1.05)}
                  onClick={handleClick}
                  sx={{
                    width: 40,
                    height: 40,
                    // background: alpha(theme.palette.grey[500], 0.08),
                    background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                  }}
                >
                  <Avatar
                    src={user?.photoUrl || user?.photoURL}
                    alt={user?.displayName}
                    sx={{
                      width: 36,
                      height: 36,
                      border: `solid 2px ${theme.palette.background.default}`,
                    }}
                  />
                </IconButton>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => openLoginModal.onTrue()}
                >
                  Login
                </Button>
              )}
            </Stack>
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 2, pb: 1.5, minWidth: '150px' }}>
              <Typography variant="subtitle2">{user?.displayName || user?.name}</Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>

            {user?.email && <Divider sx={{ borderStyle: 'dashed' }} />}

            {user?.email && (
              <Stack sx={{ p: 1 }}>
                {OPTIONS.map((option) => (
                  <Link
                    key={option.label}
                    component={RouterLink}
                    href={option.linkTo}
                    onClick={handleClose}
                  >
                    <MenuItem key={option.label}>{option.label}</MenuItem>
                  </Link>
                ))}
              </Stack>
            )}

            {user?.email && <Divider sx={{ borderStyle: 'dashed' }} />}

            {user?.email ? (
              <MenuItem
                onClick={handleLogout}
                sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
              >
                Logout
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  openLoginModal.onTrue();
                  handleClose();
                }}
                sx={{ m: 1, fontWeight: 'fontWeightBold' }}
              >
                Log In
              </MenuItem>
            )}
          </Popover>

          {!mdUp && <NavMobile data={navConfig} />}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
