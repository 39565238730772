import PropTypes from 'prop-types';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, getStorage, uploadBytes, getDownloadURL } from 'firebase/storage';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { alpha, styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from '@mui/material/CircularProgress';

import { paths } from 'src/routes/paths';
import Iconify from 'src/components/iconify';
import { useActiveLink } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import TextMaxLine from 'src/components/text-max-line';
import { useResponsive } from 'src/hooks/use-responsive';
import { DB, useAuthContext } from 'src/context/AuthContext';

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// ----------------------------------------------------------------------
const navigations = [
  {
    title: 'Personal Info',
    path: paths.account.personal,
    icon: <Iconify icon="carbon:user" />,
  },
  {
    title: 'Security',
    path: paths.account.secuirty,
    icon: <Iconify icon="hugeicons:lock-key" />,
  },
  {
    title: 'Bookings',
    path: paths.account.orders,
    icon: <Iconify icon="carbon:document" />,
  },
];

const Googlenavigations = [
  {
    title: 'Personal Info',
    path: paths.account.personal,
    icon: <Iconify icon="carbon:user" />,
  },

  {
    title: 'Bookings',
    path: paths.account.orders,
    icon: <Iconify icon="carbon:document" />,
  },
];

export default function Nav({ open, onClose }) {
  const mdUp = useResponsive('up', 'md');

  const { user, updateUser } = useAuthContext();

  const fromGoogle = user?.providerData[0]?.providerId === 'google.com';

  const isPictureLoading = useBoolean(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      isPictureLoading.onTrue();
      const storage = getStorage();
      const storageRef = ref(storage, `users/${user?.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      // Update the user's photoURL in Firestore
      const userDocRef = doc(DB, 'users', user?.uid);

      await updateDoc(userDocRef, { photoUrl: downloadURL });
      updateUser({ ...user, photoUrl: downloadURL });
      isPictureLoading.onFalse();
      // Update the user's photoURL in your auth system if necessary
    }
  };

  const renderContent = (
    <Stack
      sx={{
        flexShrink: 0,
        borderRadius: 2,
        width: 1,
        ...(mdUp && {
          width: 280,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
        }),
      }}
    >
      <Stack spacing={2} sx={{ p: 3, pb: 2 }}>
        <Stack spacing={2} direction="row" alignItems="center">
          {isPictureLoading?.value ? (
            <CircularProgress sx={{ m: 2 }} />
          ) : (
            <Avatar
              src={user?.photoUrl || user?.photoURL}
              alt={user?.displayName}
              sx={{ width: 70, height: 70 }}
            />
          )}
          {!isPictureLoading.value && (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                typography: 'caption',
                cursor: 'pointer',
                '&:hover': { opacity: 0.72 },
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<Iconify icon="carbon:edit" sx={{ mr: 1 }} />}
              >
                Upload
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  accept="image/png, image/jpeg"
                />
              </Button>
            </Stack>
          )}
        </Stack>

        <Stack spacing={0.5}>
          <TextMaxLine variant="subtitle1" line={1}>
            {user?.displayName}
          </TextMaxLine>
          <TextMaxLine variant="body2" line={1} sx={{ color: 'text.secondary' }}>
            {user?.email}
          </TextMaxLine>
        </Stack>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        {(fromGoogle ? Googlenavigations : navigations).map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack sx={{ my: 1, px: 2 }}>
        <ListItemButton
          sx={{
            px: 1,
            height: 44,
            borderRadius: 1,
          }}
        >
          <ListItemIcon>
            <Iconify icon="carbon:logout" sx={{ color: 'error.main' }} />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              typography: 'body2',
              color: 'error.main',
            }}
          />
        </ListItemButton>
      </Stack>
    </Stack>
  );

  return (
    <>
      {mdUp ? (
        renderContent
      ) : (
        <Drawer
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: 280,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}

Nav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

// ----------------------------------------------------------------------

function NavItem({ item }) {
  const active = useActiveLink(item.path);

  return (
    <Link
      component={RouterLink}
      key={item.title}
      href={item.path}
      color={active ? 'primary' : 'inherit'}
      underline="none"
    >
      <ListItemButton
        sx={{
          px: 1,
          height: 44,
          borderRadius: 1,
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            typography: 'body2',
            ...(active && {
              typography: 'subtitle2',
            }),
          }}
        />
      </ListItemButton>
    </Link>
  );
}

NavItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};
