import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { useAuthContext } from 'src/context/AuthContext';
import { usePropertyContext } from 'src/context/PropertyContext';
import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function RegisterBackgroundView() {
  const passwordShow = useBoolean();

  const { register, loginWithGoogle } = useAuthContext();
  const { openRegisterModal, openLoginModal } = usePropertyContext();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .required('Full name is required')
      .min(6, 'Mininum 6 characters')
      .max(15, 'Maximum 15 characters'),
    email: Yup.string().required('Email is required').email('That is not an email'),
    phoneNumber: Yup.string().required('Phone Number is required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
    // confirmPassword: Yup.string()
    //   .required('Confirm password is required')
    //   .oneOf([Yup.ref('password')], "Password's not match"),
  });

  const defaultValues = {
    name: '',
    email: '',
    password: '',
    // confirmPassword: '',
    phoneNumber: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await register(data);
      reset();
      // close the modal
      openRegisterModal.onFalse();
    } catch (error) {
      console.error(error);
    }
  });

  const onClickLogin = () => {
    openRegisterModal.onFalse();
    openLoginModal.onTrue();
  };

  const onClickGoogle = () => {
    loginWithGoogle();
    openRegisterModal.onFalse();
  };

  const renderHead = (
    <div>
      <Typography variant="h3" paragraph>
        Get Started
      </Typography>

      <Stack direction="row" pb={2} spacing={1}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Already have an account?
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={onClickLogin}
        >
          Login
        </Typography>
      </Stack>
    </div>
  );

  const renderSocials = (
    <Stack direction="row" spacing={2}>
      <Button fullWidth size="large" color="inherit" variant="outlined" onClick={onClickGoogle}>
        <Iconify icon="logos:google-icon" width={24} />
      </Button>

      {/* <Button fullWidth size="large" color="inherit" variant="outlined">
        <Iconify icon="carbon:logo-facebook" width={24} sx={{ color: '#1877F2' }} />
      </Button>

      <Button color="inherit" fullWidth variant="outlined" size="large">
        <Iconify icon="carbon:logo-github" width={24} sx={{ color: 'text.primary' }} />
      </Button> */}
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField name="name" label="Full Name" />

        <RHFTextField name="email" label="Email address" />
        <RHFTextField name="phoneNumber" label="Phone Number" />

        <RHFTextField
          name="password"
          label="Password"
          type={passwordShow.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify icon={passwordShow.value ? 'carbon:view' : 'carbon:view-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {/* <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          type={passwordShow.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify icon={passwordShow.value ? 'carbon:view' : 'carbon:view-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Register
        </LoadingButton>

        <Typography variant="caption" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
          {`I agree to `}
          <Link color="text.primary" href="#" underline="always">
            Terms of Service
          </Link>
          {` and `}
          <Link color="text.primary" href="#" underline="always">
            Privacy Policy.
          </Link>
        </Typography>
      </Stack>
    </FormProvider>
  );

  return (
    <Box sx={{ minWidth: { xs: '100%', sm: '400px' } }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ pt: 2 }}>
        <Button color="inherit" onClick={() => openRegisterModal.onFalse()}>
          <Iconify icon="ic:round-close" width={24} />
        </Button>
      </Stack>
      <Box sx={{ p: 3, pt: 0 }}>
        {renderHead}

        {renderForm}

        <Divider sx={{ my: 2 }}>
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            or continue with
          </Typography>
        </Divider>

        {renderSocials}
      </Box>
    </Box>
  );
}
